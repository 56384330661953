<template>
  <div>
    <Menu />
    <div>
      <!-- crumbs -->
      <div class="crumbs">
        <div class="content d-flex d-flex-middle">
          <p class="color9 fontSize12">当前所在位置：</p>
          <router-link to="/" class="color9 fontSize12">首页</router-link>
          <p class="color9 fontSize12 and">></p>
          <p class="color fontSize12">选课中心</p>
        </div>
      </div>
      <!-- classify -->
      <div class="classify">
        <div class="content classifyList bg_fff">
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">课程类型</p>
            <div class="classifyLi_list type d-flex d-flex-middle d-flex-wrap">
              <p :class="{active:vuesDictionActive==-1}" @click="changeCourseType(-1,-1)">全部</p>
              <p v-for="(item,index) in vuesDictionaryList" :key="index" :class="{active:vuesDictionActive==index}" @click="changeCourseType(item.key,index)">{{item.value}}</p>
            </div>
          </div>
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">行业分类</p>
            <div class="classification d-flex d-flex-middle d-flex-wrap">
              <p :class="{active:industryActive==index}" v-for="(item,index) in industryList" :key="index" @click="changeIndustryActive(item.Industry_Id,index)">{{item.Industry_Title}}</p>
            </div>
          </div>
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">考试分类</p>
            <div class="cl_list">
              <div class="cl_li" v-show="industryActive==index" v-for="(item,index) in industryList" :key="index">
                <div class="classifyLi_list d-flex d-flex-middle d-flex-wrap">
                  <p :class="{active:industryChilActive==indexClil}" v-for="(itemClil,indexClil) in item.chil" :key="indexClil" @click="changeIndustryChilActive(itemClil.Industry_Id,indexClil)">{{itemClil.Industry_Title}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- list -->
      <div class="list">
        <div class="content d-flex d-flex-middle d-flex-wrap">
          <router-link :to="'/Curriculum/' + item.course_Id" class="ecLi" v-for="(item,index) in listData" :key="index">
            <div class="ec_content">
              <div class="ec_img" :style="{'background-image': 'url('+$store.state.httpUrl + item.course_ImaPath+')'}"></div>
              <div class="ec_info">
                <p class="ec_title">{{item.course_Title}}</p>
                <div class="ec_bottom d-flex d-flex-middle d-flex-between">
                  <div class="ec_price">
                    <p>￥{{item.course_Price}}</p>
                    <p>特惠价</p>
                  </div>
                  <div class="ec_icon">
                    <img src="../assets/images/audition.png" />
                    <p>免费试听</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!--  -->
      <div class="none" v-if="listData.length==0">
        <img src="../assets/images/none.png"/>
      </div>
      <!--  -->
      <div style="text-align: center; margin-top: 60px;margin-bottom: 60px;">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total" :page-size="8">
        </el-pagination>
      </div>
    </div>
    <Foot />
    <RightGuide />

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { industry, vuesDictionaryByKey, splitListCourse } from "@/api/app"
export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      vuesDictionaryList: [],	//课程分类 
      vuesDictionActive: -1,	//课程分类 active
      industryActive: 0,	//active 一级分类 
      industryChilActive: -1,	//active 二级分类
      industryList: [],// 所有分类数据，对应页面行业分类用一级分类，考试分类用二级分类
      listData: [],		//数据列表
      total: 0,//总页数
    };
  },
  mounted() {
    // this.industryActive = this.$route.query.pIndex
    // this.industryChilActive = this.$route.query.index

    this.getVuesDictionaryByKey()
  },

  methods: {

    //点击课程分类
    changeCourseType(id, index) {
      this.vuesDictionActive = index
      this.getPlitListCourse()
    },

    //点击行业分类
    changeIndustryActive(id, index) {
      this.industryChilActive = -1
      this.industryActive = index
      this.getPlitListCourse()
    },
    //点击考试分类
    changeIndustryChilActive(id, index) {
      this.industryChilActive = index
      this.getPlitListCourse()
    },

    //课程分类
    getVuesDictionaryByKey() {
      const params = { dicNos: 'CourseType' }
      vuesDictionaryByKey(params).then((res) => {
        if (res) {
          const data = res[0].data
          this.vuesDictionaryList = data
          //获取全部分类
          this.getIndustry()
        }
      })
    },

    //全部分类
    getIndustry() {
      var that = this
      const params = {}
      industry(params).then((res) => {
        if (res.status) {
          const data = res.data
          that.industryList = data
          console.log(data)
          //首页导航分类传参 匹配当前选中的分类
          if (that.$route.query.id && that.$route.query.pId) {
            // console.log(that.$route.query.id)
            that.industryList.map((item, index) => {
              if(that.$route.query.pId == item.Industry_Id){
                that.industryActive = index
                item.chil.map((v,i)=>{
                  if(v.Industry_Id == that.$route.query.id){
                    that.industryChilActive = i
                  }
                })
              }
            });
          }

          //获取列表数据
          this.getPlitListCourse()
        }
      })
    },

    //分页
    handleCurrentChange(page) {
      this.getPlitListCourse(page)
    },

    //数据列表
    getPlitListCourse(page) {
      const params = {
        industry_Id: '',
        course_Type: '',
        val: '',
        page: page || 1,
      }

      //判断是否选中考试（二级）分类, 二级选中就传二级,反之传一级
      if (this.industryChilActive == -1) {
        params.industry_Id = this.industryList[this.industryActive].Industry_Id
      } else {
        params.industry_Id = this.industryList[this.industryActive].chil[this.industryChilActive].Industry_Id
      }

      //课程分类 选择全部 就传空
      if (this.vuesDictionActive == -1) {
        params.course_Type = ""
      } else {
        params.course_Type = this.vuesDictionaryList[this.vuesDictionActive].key
      }

      splitListCourse(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.listData = data.listCourse
          this.total = data.totalCount
        }
      })

    },
  },
};
</script>

<style >
/*  */
body {
  background-color: #fafafa;
}
/*  */
.classify {
  margin-bottom: 50px;
}
.classify .content {
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 10px;
}
.classifyLi {
  margin-bottom: 10px;
}
.classifyLi .classifyLi_title {
  width: 100px;
  line-height: 30px;
  color: #999;
  font-size: 14px;
  margin: 5px 0;
}
.classifyLi_list,
.cl_list {
  flex: 1;
}
.classifyLi_list p,
.classification p {
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  color: #333;
  margin: 5px;
}
.classifyLi_list .active,
.classification .active {
  background-color: #0087ed;
  color: #fff;
  border-radius: 5px;
}
.cl_li {
  /* display: none; */
}
.cl_li.show {
  display: block;
}
/*  */
.list {
  padding-bottom: 100px;
}
.ecLi {
  width: 25%;
  margin-bottom: 20px;
}
.ec_content {
  width: 294px;
  margin: 0 auto;
  height: 300px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.ec_img {
  height: 164px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ec_img img {
  position: absolute;
  right: 0;
  top: 0;
  width: 86px;
  height: 86px;
}
.ec_info {
  box-sizing: border-box;
  padding: 10px;
}
.ec_title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.ec_bottom {
  margin-top: 20px;
}
.ec_price p {
  color: #e02727;
  font-size: 14px;
}
.ec_price p:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  text-indent: -5px;
}
.ec_icon {
  text-align: center;
  color: #0091ff;
  font-size: 14px;
}
.ec_icon img {
  width: 30px;
  height: 30px;
}
</style>